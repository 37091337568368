import Grid from '@mui/material/Grid';
import { New as LinkNew } from './News';
import ModalImage from "react-modal-image";

const Main = ({ col, desc="", md=6, xs=12, isImage=false }) => {
  return (
    <div class="row section featured topspace">
    <h2 class="section-title">
      { desc === "" && <div className="separator" />}
      { desc !== "" && <span>{desc}</span>}
    </h2>
    <Grid container spacing={1}>
      {col.map((d, i) => (
        <Grid item xs={xs} md={md} key={i}>
          {!isImage && <LinkNew desc={d.desc} url={d.url} link={d.isLink === true} />}
          {isImage && <ModalImage small={d.url} large={d.url} alt={d.desc} /> }
        </Grid>
      ))}
    </Grid>
  </div>
  );
}

export default Main;
