import React, { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';

const fetchAirQuality = async () => {
  const rs = await fetch(`https://www.airvisual.com/widgets/custom/67370c1a01af34e722a86322`, {
    method: "GET",
  });

  return rs.json();
}

const Component = () => {
  const [rs, setRs] = useState(null);

  const { data, status } = useQuery({
    queryKey: ["airquality"],
    queryFn: () => fetchAirQuality(),
  });

  useEffect(() => {
    if (status === "success") {
      setRs(data);
    }
  }, [data, status]);

  console.log(rs?.html);

  return (
    <div name="airvisual_widget" key="67370c1a01af34e722a86322" dangerouslySetInnerHTML={{ __html: rs?.html.replace("row", "row2") }}/>
  );
}

export default Component;
